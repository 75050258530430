import Vue from 'vue'
import VueRouter from 'vue-router'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/js/flexible.js'
import '@/assets/css/main.css'

const routes = [
  { path: '/', component: () => import('@/pages/Home.vue') },
  { path: '/information', component: () => import('@/pages/Information.vue') },
  { path: '/license', component: () => import('@/pages/License') }
]

// 3. 创建 router 实例，然后传 `routes` 配置
// 你还可以传别的配置参数, 不过先这么简单着吧。
const router = new VueRouter({
  mode: 'history',
  routes // (缩写) 相当于 routes: routes
})

Vue.use(VueRouter);
Vue.use(ElementUI)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
